<script setup>
   import { computed } from 'vue';
   import { Link } from '@inertiajs/vue3';

   const props = defineProps({
      href: {
         type: String,
         required: true,
      },
      active: {
         type: Boolean,
      },
   });

   const classes = computed(() => (props.active ? 'inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-primary-color-dark focus:outline-none transition duration-150 ease-in-out' : 'inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-gray-500 hover:text-gray-700  focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out'),
   );
</script>

<template>
   <Link
      :href="href"
      :class="classes"
   >
      <slot />
   </Link>
</template>
