<template>
   <div class="relative isolate overflow-hidden py-14 torn-edge-mask">
      <div class="absolute inset-0 -z-10 h-full w-full object-cover">
         <img
            :srcset="$page.props.pageBlocks.banner.data.media.original_url"
            class="bg-cover bg-no-repeat bg-center absolute inset-0 -z-10 h-full w-full object-cover"
            role="presentation"
         >
         <div
            class="h-full w-full left-0 top-0 absolute bg-neutral-950 bg-opacity-45 hover-animation"
         />
      </div>
      <div class="mx-auto max-w-6xl py-16 sm:py-48 lg:py-56 px-4 md:px-0">
         <div class="text-center">
            <h1
               class="text-5xl md:text-6xl lg:text-7xl font-bold text-white"
            >
               {{ $page.props.pageBlocks.banner.data.title }}
            </h1>
            <p class="mt-6 text-lg lg:text-xl leading-8 font-semibold text-gray-200">
               {{ $page.props.pageBlocks.banner.data.subtitle }}
            </p>
         </div>
      </div>
   </div>
</template>

<script setup></script>

<style scoped></style>
