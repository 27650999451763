<template>
   <div class="sticky top-0 z-30 shadow-md bg-[#E5E7EB] bg-[url('/images/header_background.png')] bg-cover">
      <nav class="max-w-8xl mx-auto sm:px-6 lg:px-8 shadow-md">
         <!-- Primary Navigation Menu -->
         <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div
               class="flex items-center justify-between lg:justify-evenly"
            >
               <!-- Logo -->
               <div class="shrink">
                  <Link :href="route('home.index')">
                     <span class="sr-only">{{
                        $page.props.app_name
                     }}</span>
                     <img
                        role="presentation"
                        class="h-20 w-20"
                        src="/images/logos/gravity_cy_logo.png"
                        alt
                     >
                  </Link>
               </div>

               <!-- Navigation Links -->
               <div
                  class="hidden lg:flex flex-grow justify-center space-x-12 sm:-my-px sm:ms-10"
               >
                  <NavLink
                     v-for="item in menuPages"
                     :key="item.slug"
                     :href="route(item.index_route)"
                     class="flex shrink items-center text-lg font-medium hover:text-primary-color"
                     :active="route().current(item.index_route)"
                  >
                     {{ item.title }}
                  </NavLink>
               </div>

               <LocaleDropdown class="shrink items-end hidden lg:flex" />

               <AccountDropdown class="shrink items-end hidden lg:flex" />

               <!-- Hamburger -->
               <div class="-me-2 flex items-center lg:hidden">
                  <button
                     @click="
                        showingNavigationDropdown =
                           !showingNavigationDropdown
                     "
                     class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                     <svg
                        class="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                     >
                        <path
                           :class="{
                              hidden: showingNavigationDropdown,
                              'inline-flex':
                                 !showingNavigationDropdown
                           }"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M4 6h16M4 12h16M4 18h16"
                        />
                        <path
                           :class="{
                              hidden: !showingNavigationDropdown,
                              'inline-flex': showingNavigationDropdown
                           }"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M6 18L18 6M6 6l12 12"
                        />
                     </svg>
                  </button>
               </div>
            </div>
         </div>

         <!-- Responsive Navigation Menu -->
         <div
            :class="{
               block: showingNavigationDropdown,
               hidden: !showingNavigationDropdown
            }"
            class="lg:hidden"
         >
            <div class="pt-2 pb-3 space-y-1">
               <ResponsiveNavLink
                  v-for="appPage in menuPages"
                  :key="appPage.slug"
                  :href="route(appPage.index_route)"
                  :active="route().current(appPage.index_route)"
                  class="flex shrink items-center text-lg font-medium hover:text-primary-color"
                  :class="[
                     route().current(appPage.index_route)
                        ? 'text-primary-color'
                        : 'text-gray-700'
                  ]"
               >
                  {{ appPage.title }}
               </ResponsiveNavLink>
               <div class="px-4">
                  <div class="text-base py-2 font-medium text-gray-800">
                     {{
                        $page.props.auth.user
                           ? $t('account.my_account')
                           : $t('account.account')
                     }}
                  </div>
                  <ResponsiveNavLink
                     v-if="$page.props.auth.user"
                     as="button"
                     :active="route().current('profile.edit')"
                     :href="route('profile.edit')"
                  >
                     {{ $t('account.profile') }}
                  </ResponsiveNavLink>
                  <ResponsiveNavLink
                     v-if="$page.props.auth.user"
                     as="button"
                     method="post"
                     :href="route('logout')"
                  >
                     {{ $t('account.logout') }}
                  </ResponsiveNavLink>
                  <ResponsiveNavLink
                     v-if="$page.props.auth.user === null"
                     as="button"
                     :href="route('login')"
                  >
                     {{ $t('account.login') }}
                  </ResponsiveNavLink>
                  <ResponsiveNavLink
                     v-if="$page.props.auth.user === null"
                     as="button"
                     :href="route('register')"
                  >
                     {{ $t('account.register') }}
                  </ResponsiveNavLink>
               </div>
               <div class="px-4">
                  <div class="text-base py-2 font-medium text-gray-800">
                     {{ $t('misc.language.name') }}
                  </div>
                  <ResponsiveNavLink
                     as="button"
                     :active="page.props.locale === 'en'"
                     method="post"
                     :href="route('locale', { locale: 'en' })"
                  >
                     <span class="fi fi-gb" />
                     {{ $t('misc.language.en') }}
                  </ResponsiveNavLink>
                  <ResponsiveNavLink
                     as="button"
                     method="post"
                     :active="page.props.locale === 'el'"
                     :href="route('locale', { locale: 'el' })"
                  >
                     <span class="fi fi-gr" />
                     {{ $t('misc.language.el') }}
                  </ResponsiveNavLink>
               </div>
            </div>
         </div>
      </nav>
   </div>
</template>

<script setup>
   import { Link, usePage } from '@inertiajs/vue3';

   import LocaleDropdown from '@/Components/Forms/LocaleDropdown.vue';
   import NavLink from '@/Components/NavLink.vue';
   import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
   import { computed, ref } from 'vue';
   import { filter } from 'lodash';
   import AccountDropdown from '@/Components/AccountDropdown.vue';

   const page = usePage();
   const showingNavigationDropdown = ref(false);

   const menuPages = computed(() => {
      return filter(page.props.pages.data, (page) => {
         return parseInt(page.show_in_menu) === 1;
      });
   });
</script>
